// Popover du footer (mentions légales)
$("[data-toggle=popover]").popover({
	html:true,
	content: function(){ return $('#legal-mentions').html()} 
});
// Fin Popover


// Affichage de la map google map si la div existe
if (document.getElementById('carte_googlemaps')) { 

  function initialisation(){
    var optionsCarte = {
      zoom: 13,
      center: new google.maps.LatLng(46.262211,5.6311458),
      'scrollwheel': false
    }
    var maCarte = new google.maps.Map(document.getElementById("carte_googlemaps"), optionsCarte);

    var optionsMarqueur = {
          position: maCarte.getCenter(),
          map: maCarte
      };
      var marqueur = new google.maps.Marker(optionsMarqueur);
  }
  google.maps.event.addDomListener(window, 'load', initialisation);

};
// Fin affichage google maps


// FORMULAIRE AJAX : Contact
if (document.getElementById('form_contact')) { 

	$(document).ready(function() {
	    // Lorsque je soumets le formulaire
	    $('#form_contact').on('submit', function(e) {
	        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire
	 
	        var $this = $(this); // L'objet jQuery du formulaire
	 
	        // Je récupère les valeurs
	        var mail = $('#email').val();
	        var nom = $('#nom').val();
	        var entreprise = $('#entreprise').val();
	        var telephone = $('#telephone').val();
	        var message = $('#message').val();

	        var data = $(this).serializeArray(); // convert form to array


            console.log(data);
	        // Je vérifie une première fois pour ne pas lancer la requête HTTP
	        // si je sais que mon PHP renverra une erreur
	        if(nom === '' || email === '' || message === '') {
	            alert("Les champs marqués d'une étoile (*) doivent êtres remplis");
	        } else {resultat = 0;
	            // Envoi de la requête HTTP en mode asynchrone
	            $.ajax({
	                url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
	                type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
	                data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
	                success: function(resultat) { // Je récupère la réponse du fichier PHP
                        console.log(resultat);
	                    if(resultat == 'ok') {
	                        document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
	                    } else {
	                        document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
	                    }
	                }
	            });
	        }
	    });
	});
};
// FIN FORMULAIRE AJAX : Contact

function onSubmit(token) {

    var $this = $('#form_contact'); // L'objet jQuery du formulaire
	 
    // Je récupère les valeurs
    var mail = $('#email').val();
    var nom = $('#nom').val();
    var entreprise = $('#entreprise').val();
    var telephone = $('#telephone').val();
    var message = $('#message').val();

    var data = $this.serializeArray(); // convert form to array

    // Je vérifie une première fois pour ne pas lancer la requête HTTP
    // si je sais que mon PHP renverra une erreur
    if(nom === '' || email === '' || message === '') {
        alert("Les champs marqués d'une étoile (*) doivent êtres remplis");
    } else {resultat = 0;
        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
            success: function(resultat) { // Je récupère la réponse du fichier PHP
                if(resultat == 'ok') {
                    document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
                } else {
                    document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
                }
            }
        });
    }
}


// Activation du swipe mobile sur le carousel
$(document).ready(function() {  

		//Enable swiping...
		$("#myCarousel").swipe( {
			//Generic swipe handler for all directions
			swipeLeft:function(event, direction, distance, duration, fingerCount) {
				$(this).carousel('next'); 
			},
			swipeRight: function() {
				$(this).carousel('prev'); 
			},
			//Default is 75px, set to 0 for demo so any distance triggers swipe
			threshold:0
		});
});



// Gestion du sous-menu

$(document).ready(function(){
    $(".dropdown").hover(            
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,false).slideDown("200");
            $(this).toggleClass('open');        
        },
        function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,false).slideUp("200");
            $(this).toggleClass('open');       
        }
    );
});